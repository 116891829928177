

















import Vue from 'vue';

export default Vue.extend({
  components: {
    TermsV1: () => import(/* webpackChunkName: "l-agb-v1" */ '@/components/legal/agb/V1.vue'),
  },
  meta() {
    return {
      title: this.$t('terms').toString(),
    };
  },
});
